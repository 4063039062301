.charts {
  &__description {
    position: absolute;
    top: 500px;
    left: 105px;
    font-family: cursive;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &__item {
      display: flex;
      gap: 0.5rem;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-family: cursive;
    padding: 0 0 0 30px;
    > .valueRange {
      display: flex;
      align-items: center;
      max-height: 35px;
    }
  }
}
.first {
  width: 40px;
  height: 17px;
  background-color: blue;
  border-radius: 16px;
}
.second {
  border-radius: 16px;
  width: 40px;
  height: 17px;
  background-color: #d24848;
}
.desc__formula1 {
  font-family: cursive;
  position: absolute;
  top: 125px;
  left: 180px;
  color: #d24848;
  z-index: 10;
}
.desc__formula2 {
  font-family: cursive;
  position: absolute;
  top: 215px;
  left: 365px;
  color: blue;
  z-index: 10;
}
.input-teorver {
  width: 90px;
  height: 35px;
}
.teor621 {
  &__description {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-family: cursive;
    position: absolute;
    top: 432px;
    left: 100px;
    z-index: 1;
    background-color: white;
    border: 1px solid #bebbbb;
    padding: 4px;
    border-radius: 10px;
    line-height: 20px;
  }
  &__overline {
    text-decoration: overline;
  }
  &__inner-desc {
    display: flex;
    align-items: center;
    gap: 4px;
    &.third-inner {
      width: 20px;
    }
  }
  &__first {
    width: 20px;
    height: 5px;
    background-color: blue;
    border-radius: 5px;
  }
  &__teylor {
    width: 20px;
    height: 5px;
    background-color: red;
    border-radius: 5px;
  }
  &__vector {
    width: 20px;
    height: 5px;
    background-color: blueviolet;
    border-radius: 5px;
    
  }
  &__second {
    width: 20px;
    height: 5px;
    background-color: green;
    border-radius: 5px;
  }
  &__third-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &__third {
    margin-left: 5px;
    width: 10px;
    height: 10px;
    background-color: blue;
    border-radius: 50%;
  }
}
