.App {
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}
.wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
  max-width: 750px;
}
.valueRange {
  display: flex;
  gap: 10px;
}
.chart {
  &__control {
    z-index: 100;
    position: absolute;
    top: 435px;
    left: 495px;
    background: white;
    opacity: 0.9;
    font-family: cursive;
    &__cos {
      position: absolute;
      left: 400px;
    }
    &__sin {
      position: absolute;
      left: 400px;
      top: 270px;
    }
    &.value-up {
      left: 450px;
      top: 130px;
      min-height: 41px;
      display: flex;
      min-width: 114px;
      justify-content: center;
      flex-direction: column;
    }
    &.value-up-left {
      top: 141px;
      left: 100px;
    }
    &.value-down {
      top: 430px;
      left: 462px;
    }
  }
  &__title-name {
    font-weight: 600;
  }
  &__formula-description {
    display: flex;
    align-items: center;
    font-family: cursive;
    min-height: 40px;
    padding: 0 0 0 30px;
  }
  &__formula-integral {
    top: -80px;
    position: absolute;
  }
  &__value {
    min-width: 60px;
    &.value-up {
      min-width: 114px;
    }
  }
}
.not_show {
  display: none;
}
.show_white {
  opacity: 0;
}
.formula-position {
  margin-right: 10px;
  min-width: 185px;
}

.normal-distribution {
  &__info {
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  &__red {
    min-width: 200px;
    color: rgb(206, 26, 26);
  }
  &__green {
    min-width: 200px;
    color: rgb(125, 179, 125);
  }
  &__orange {
    min-width: 200px;
    color: rgb(196, 141, 40);
  }
}
